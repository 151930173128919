<template>
  <action-dispatcher action="homeworks/get" :parameters="$route.params.id">
    <div class="view-class-homeworks">
      <div class="d-flex justify-content-end mb-5">
        <router-link
          :to="{
            name: 'admin-new-homework',
            query: {
              class: $route.params.id
            }
          }"
          class="btn btn-primary"
        >
          Nova atividade
        </router-link>
      </div>

      <div
        v-for="(c, i) in homeworks"
        :key="`class-${i}`"
        class="card d-flex flex-row align-items-center p-3 mb-4"
      >
        <router-link
          class="flex-fill"
          :to="{ name: 'admin-homework-answers', params: { id: c.id } }"
        >
          {{ c.title }}
        </router-link>
        <router-link
          :to="{ name: 'admin-edit-homework', params: { id: c.id } }"
          class="btn m-0 p-0 mr-2"
        >
          <i class="icon-edit"></i>
        </router-link>
        <button class="btn text-dangers m-0 p-0" @click="openDeleteDialog(c)">
          <i class="icon-trash text-danger"></i>
        </button>
      </div>
      <div class="d-flex justify-content-center">
        <button
          v-if="show_more"
          class="btn btn-outline-primary"
          @click="loadMore"
        >
          <b-spinner v-if="loading_more" variant="light" small></b-spinner>
          <span v-else>Ver mais</span>
        </button>
        <p v-else class="text-secondary">
          Este é o final da lista.
        </p>
      </div>
    </div>
    <confirmation
      v-model="deleteDialog"
      @confirm="submitDelete"
      :text="deleteConfirmationText"
      confirm-button-text="Sim, excluir!"
      confirm-button-class="btn-danger"
    >
      <template #img>
        <h1><i class="icon-construction"></i></h1>
      </template>
    </confirmation>
  </action-dispatcher>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      homeworkToDelete: null,
      deleteLoading: false,
      deleteDialog: false,
      loading_more: false,
      show_more: true
    };
  },
  computed: {
    ...mapState("homeworks", ["homeworks"]),
    deleteConfirmationText() {
      if (this.homeworkToDelete) {
        const limit = 30;
        let title = this.homeworkToDelete.title;
        let ellipsis = "";
        if (title.length > limit) {
          title = this.homeworkToDelete.title.slice(0, limit);
          ellipsis = "...";
        }

        return `Tem certeza que deseja excluir a atividade: ${title}${ellipsis}?`;
      }

      return "";
    }
  },
  methods: {
    openDeleteDialog(homework) {
      this.homeworkToDelete = homework;
      this.deleteDialog = true;
    },
    submitDelete() {
      this.deleteLoading = true;
      this.$store
        .dispatch("homeworks/delete", this.homeworkToDelete.id)
        .then(() => {
          this.$message.success("A atividade foi deletada");
          this.homeworkToDelete = null;
          this.deleteDialog = false;
          this.$refs.dispatcher.dispatch();
        })
        .catch(e => {
          this.$message.error("Não foi possível excluir a atividade");
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    loadMore() {
      if (this.loading_more) return;

      this.loading_more = true;
      this.$store
        .dispatch("homeworks/get")
        .then(data => {
          if (data.length == 0) {
            this.show_more = false;
          }
        })
        .finally(() => {
          this.loading_more = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.view-class-homeworks {
}
</style>
